<script>
import { analytics } from "@/config/api/anlaytics";

/**
 * Widget Component
 */
export default {
  data() {
    return {
      statData: [
        {
          title: "Total User",
          value: 0,
          loading: false,
        },
        {
          title: "Available Vendors",
          value: 0,
          loading: false,
        },
        {
          title: "Total Tribe",
          value: 0,
          loading: false,
        },
        {
          title: "Program Count",
          value: 0,
          loading: false,
        },
        {
          title: "Pending Bookings",
          value: 0,
          loading: false,
        },
        {
          title: "Approved Bookings",
          value: 0,
          loading: false,
        },
        {
          title: "User Posts",
          value: 0,
          loading: false,
        },

        {
          title: "Blogs Count",
          value: 0,
          loading: false,
        },
      ],
    };
  },
  created() {
    this.loadUserCount();
    this.loadVendorCount();
    this.loadTribeCount();
    this.loadProgramCount();
    this.loadBookingsStatusCount();
    this.loadUserPostCount();
    this.loadBlogsCount();
  },
  methods: {
    loadUserCount() {
      const api = analytics.userCount;
      this.statData[0].loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.statData[0].value = res.data.userCount;
        })
        .finally(() => {
          this.statData[0].loading = false;
        });
    },
    loadVendorCount() {
      const api = analytics.vendorCount;
      this.statData[1].loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.statData[1].value = res.data.vendorCount;
        })
        .finally(() => {
          this.statData[1].loading = false;
        });
    },
    loadTribeCount() {
      const api = analytics.tribeCount;
      this.statData[2].loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.statData[2].value = res.data.tribeCount;
        })
        .finally(() => {
          this.statData[2].loading = false;
        });
    },
    loadProgramCount() {
      const api = analytics.programCount;
      this.statData[3].loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.statData[3].value = res.data.programCount;
        })
        .finally(() => {
          this.statData[3].loading = false;
        });
    },
    loadBookingsStatusCount() {
      const api = analytics.bookingsStatusCount;
      this.statData[4].loading = true;
      this.statData[5].loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.statData[4].value = res.data.PendingBookings;
          this.statData[5].value = res.data.ApprovedBookings;
        })
        .finally(() => {
          this.statData[4].loading = false;
          this.statData[5].loading = false;
        });
    },
    loadUserPostCount() {
      const api = analytics.userPostCount;
      this.statData[6].loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.statData[6].value = res.data.userPostCount;
        })
        .finally(() => {
          this.statData[6].loading = false;
        });
    },
    loadBlogsCount() {
      const api = analytics.blogsCount;
      this.statData[7].loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.statData[7].value = res.data.blogsCount;
        })
        .finally(() => {
          this.statData[7].loading = false;
        });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-3 col-sm-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <div v-if="data.loading">
                <b-skeleton width="55%"></b-skeleton>
              </div>
              <div v-else>
                <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
                <h4 class="mb-0">{{ data.value }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
