<template>
  <div class="card">
    <h6 class="text-center my-2">Bookings</h6>
    <apexchart type="bar" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
import { analytics } from "@/config/api/anlaytics";
export default {
  data() {
    return {
      booking: [],
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    options() {
      return {
        chart: {
          id: "booking-data",
        },
        xaxis: {
          categories: ["All Bookings", "Pending Bookings", "Approved Bookings"],
        },
      };
    },
    series() {
      return [
        {
          name: "count",
          data: [
            this.booking.allBookingsCount || 0,
            this.booking.pendingBookingsCount || 0,
            this.booking.approvedBookingsCount || 0,
          ],
        },
      ];
    },
  },
  methods: {
    loadData() {
      const api = analytics.bookingsData;
      this.generateAPI(api)
        .then((res) => {
          this.booking = res.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally(() => {
          console.log("Loading completed!");
        });
    },
  },
};
</script>
