export const analytics = {
  userCount: {
    url: "analytics/userCount",
    method: "GET",
  },
  vendorCount: {
    url: "analytics/vendorCount",
    method: "GET",
  },
  tribeCount: {
    url: "analytics/tribeCount",
    method: "GET",
  },
  programCount: {
    url: "analytics/programCount",
    method: "GET",
  },
  bookingsStatusCount: {
    url: "analytics/bookingsStatusCount",
    method: "GET",
  },
  userPostCount: {
    url: "analytics/userPostCount",
    method: "GET",
  },
  blogsCount: {
    url: "analytics/blogsCount",
    method: "GET",
  },
  bookingsData: {
    url: "analytics/bookingsData",
    method: "GET",
  },
};
